const KmlConverter = () => import(/* webpackChunkName: "KmlConverter" */ '../views/Utility/KmlConverter')
const SearchImageMobile = () => import('../views/Utility/SearchImageMobile');
const DownloadPlacement = () => import('../views/SovMap/DownloadPlacement');

const utilityRouter = [
    {
        path: '/kml-converter',
        name: 'kmlconverter',
        component: KmlConverter,
        meta: { isLinkAja: true }
    },
    {
        path: '/search-image',
        name: 'searchimage',
        component: SearchImageMobile,
        meta: { isLinkAja: true }
    },
    {
        path: '/download-placement',
        name: 'download-placement',
        component: DownloadPlacement
      }
]

export default utilityRouter;
